import clsx from "clsx";
import {HTMLAttributes, ReactNode} from "react";

export default function PropertyContainer({className = '', label, value, children, postfixNode = null, prefixNode = null, borderless = false}: HTMLAttributes<HTMLElement> & {
    label: string,
    value: string|ReactNode,
    postfixNode?: ReactNode,
    prefixNode?: ReactNode,
    borderless?: boolean
}) {

    return (
        <div className={clsx([className, !borderless &&  "border border-stone-200", "rounded-lg"])}>

            <div className="flex justify-between items-center px-3 py-1.5 gap-3">
                {prefixNode}
                <div className="grow min-w-0">
                    <dt className="uppercase text-2xs font-medium tracking-wider text-stone-500 leading-tight mt-1 mb-0.5">{label}</dt>
                    <dd className="truncate">{value}</dd>
                </div>
                {postfixNode}
            </div>

            {children}

        </div>
    );

}
